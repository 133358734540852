import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import axios from 'axios';

const UserProfileLite = (props) => {
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState(props.match.params.id || null); // Ensure doctor ID is set correctly
  const [account, setAccount] = useState({});
  const [loadings, setLoadings] = useState(false);

  const requestOptions = {
    method: 'POST',
    headers: {
      'x-auth-token': doctor, // Assuming this is the token required for authentication
      'Content-Type': 'application/json' // Set proper content type
    },
    body: null
  };

  useEffect(() => {
    // Fetch to validate token
    const fetchTokenValidation = async () => {
      try {
        setLoadings(true); // Start loading

        const response = await fetch('https://api.aiimspatna.edu.in/doctor/tokenIsValid', requestOptions);
        const result = await response.json();

        if (result.status === true) {
          // Token is valid, fetch doctor details
          const doctorResponse = await axios.get(`https://api.aiimspatna.edu.in/doctor/${result.user.id}`);
          setAccount(doctorResponse.data); // Set account details
        } else {
          // Redirect to login if the token is invalid
          window.location.replace("https://aiimspatna.edu.in/doctor-login/");
        }

        setLoadings(false); // Stop loading
      } catch (error) {
        setLoadings(false);
        console.error("Error during token validation:", error);
      }
    };

    // Call the token validation function
    if (doctor) {
      fetchTokenValidation();
    }

  }, [doctor]);

  useEffect(() => {
    // Fetch additional data related to the doctor
    const fetchDoctorData = async () => {
      try {
        setLoadings(true); // Start loading
        const baseURL = `https://api.aiimspatna.edu.in/doctor/token/${doctor}`;
        const response = await axios.get(baseURL);
        setData(response.data); // Set data
        setLoadings(false); // Stop loading
      } catch (error) {
        setLoadings(false); // Stop loading on error
        console.error("Error fetching doctor data:", error);
      }
    };

    if (doctor) {
      fetchDoctorData();
    }
  }, [doctor]);

  return (
    <>
      {loadings ? (
        <h1>Loading....</h1>
      ) : (
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="Doctor Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
            {account.isNodal ? (
              <div>
                <h4>(Nodal)</h4>
                <ul>
                  {data.user && data.user.map((post) => (
                    <li key={post.token}>
                      <Button
                        onClick={() => {
                          setDoctor(post.token);
                          window.location.replace(`https://doctor.aiimspatna.edu.in/user-profile-lite/${post.token}`);
                        }}
                        theme="secondary"
                        style={{ margin: "10px" }}
                      >
                        {post.useremail}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Row>

          <Row>
            <Col lg="12">
              <UserAccountDetails token={doctor} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default UserProfileLite;
