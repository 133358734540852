import React from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Container,
  Button
 
} from "shards-react";

const Login = () => (
  <Container fluid className="main-content-container px-12 pb-12">
    <div className="error">
      <div className="error__content">
        {/* <h2>500</h2>
        <h3>Something went wrong!</h3>
        <p>There was a problem on our end. Please try again later.</p> */}

<CardHeader className="border-bottom">
                <h6 className="m-0">Login to Doctor Dashboard</h6>

              
              <br></br>
              <Row form>
                {/* First Name */}

                {/* Last Name */}
                <Col md="12" className="form-group">
                  {/* <label htmlFor="feLastName">1</label>  */}
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    value="username"
                    onChange={() => { }}
                  />
                </Col>
              </Row>

            
              <Row form>
                {/* First Name */}

                {/* Last Name */}
                <Col md="12" className="form-group">
                  {/* <label htmlFor="feLastName">1</label>  */}
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    value="password"
                    onChange={() => { }}
                  />
                </Col>
              </Row>
        <Button pill> Login &rarr;</Button>
        </CardHeader>
      </div>
    </div>
  </Container>
);

export default Login;
