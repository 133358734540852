import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

const UserActions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [doctorName, setDoctorName] = useState("Unknown Doctor");
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams(); // Assuming id is the token

  // Fetch doctor details based on the token
  const getDoctorDetails = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "x-auth-token": id },
        body: null,
      };

      const response = await fetch(
        "https://api.aiimspatna.edu.in/doctor/tokenIsValid",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      if (data && data.user && data.user.id) {
        // If valid data is returned, fetch doctor details
        getDoctor(data.user.id);
        setDoctorName(data.user.displayName || "Unknown Doctor");
      } else {
        console.error("Invalid user data received from tokenIsValid");
      }
    } catch (error) {
      console.error("Error fetching token validation:", error);
    }
  };

  // Fetch specific doctor details
  const getDoctor = async (doctorId) => {
    try {
      const response = await axios.get(
        `https://api.aiimspatna.edu.in/doctor/${doctorId}`
      );

      const doctorData = response.data;
      if (doctorData && doctorData.profilepic) {
        setProfilePic(doctorData.profilepic);
      } else {
        console.warn("No profile picture available for this doctor");
      }
      setUserDetails(doctorData);
    } catch (error) {
      console.error("Error fetching doctor details:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getDoctorDetails();
    } else {
      console.warn("No token provided");
    }
  }, [id]);

  const toggleUserActions = () => {
    setIsVisible(!isVisible);
  };

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src={
            profilePic
              ? `https://aiimspatna.edu.in/img/doctors/${profilePic}`
              : require("./../../../../images/avatars/docr.jpg") // fallback if no profile picture
          }
          alt="User Avatar"
          width={50}
          height={40}
        />
        <span className="d-none d-md-inline-block">{doctorName}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={isVisible}>
        <DropdownItem tag={Link} to="/" className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
