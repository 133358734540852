import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
//import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
// import Icon from '@material-ui/core/Icon';
import CustomFileUpload from "../../components/components-overview/CustomFileUpload";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { FaceRetouchingNatural } from "@mui/icons-material";
const UserAccountDetails = ({ title, token }) => {
  // console.log("token is rndu",token);
  const [image, setImage] = useState(null)
  const [value, setValue] = useState(1);

  const requestOptions = {
    method: "POST",
    headers: { "x-auth-token": token },
    body: null,
    "Access-Control-Allow-Origin": "*"
  };

  // const [profileid,setprofileid]=useState();
  const [inputFields, setInputFields] = useState([
    {
      education_degree1: "",
      education_college1: "",
      education_years1: "",
      education_remarks1: ""
    }
  ]);

  const [inputFieldsaedu, setInputFieldsaedu] = useState([
    { additional_qualification1: "", year_of_award1: "", awarding_body1: "" }
  ]);

  const [inputFieldsexp, setInputFieldsexp] = useState([
    {
      experience_postheld1: "",
      experience_from1: "",
      experience_to1: "",
      experience_instiute1: ""
    }
  ]);

  const [inputFieldsaoi, setInputFieldsaoi] = useState([
    { area_of_intrest1: "" }
  ]);
  const [inputFieldsmopb, setInputFieldsmopb] = useState([
    { member_of_professional_bodies1: "" }
  ]);

  const [inputFieldspi, setInputFieldspi] = useState([
    {
      research_as_pi_topic1: "",
      research_as_pi_funded1: "",
      research_as_pi_funding_agency1: "",
      research_as_pi_year_to_start1: "",
      research_as_pi_year_of_completion1: ""
    }
  ]);
  const [inputFieldscopi, setInputFieldscopi] = useState([
    {
      research_as_co_pi_topic1: "",
      research_as_co_pi_funded1: "",
      research_as_co_pi_funding_agency1: "",
      research_as_co_pi_year_to_start1: "",
      research_as_co_pi_year_of_completion1: ""
    }
  ]);
  const [account, setAccount] = useState({
    fname: "",
    lname: "",
    designation: "",
    doj: "",
    dob: "",
    department: "",
    publications_file: "",
    inputFields: [
      {
        education_degree1: "",
        education_college1: "",
        education_years1: "",
        education_remarks1: ""
      }
    ]
  });
  const changefirstname = e => {
    setAccount({
      ...account,
      fname: e.target.value
    });
  };
  const changesecondname = e => {
    setAccount({
      ...account,
      lname: e.target.value
    });
  };
  const changedob = e => {
    setAccount({
      ...account,
      dob: e.target.value
    });
  };
  const changedoj = e => {
    setAccount({
      ...account,
      doj: e.target.value
    });
  };
  const changedepartment = e => {
    setAccount({
      ...account,
      department: e.target.value
    });
  };
  const changedesignation = e => {
    setAccount({
      ...account,
      designation: e.target.value
    });
  };
  const changepub = e => {
    setAccount({
      ...account,
      publications_file: e.target.value
    });
    console.log(account);
  };
  const handleChange = event => {
    const values = [...account];
    values[event.target.name] = event.target.value;
    console.log(values);
    // setInputFields(values);
    setAccount(values);
  };
  const handleChangedoc = e => {
    // let name = e.target.name;
    // let value = e.target.value;
    let url = "https://api.aiimspatna.edu.in/upload/";
    let file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    uploadFile(url, file);
    // account[name] = value;
    // setAccount(account);
  };
  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    // setInputFields(values);
    setInputFields(values);
  };
  const handleChangeInputaedu = (index, event) => {
    const values = [...inputFieldsaedu];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldsaedu(values);
  };
  const handleChangeInputexp = (index, event) => {
    const values = [...inputFieldsexp];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldsexp(values);
  };
  const handleChangeInputaoi = (index, event) => {
    const values = [...inputFieldsaoi];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldsaoi(values);
  };
  const handleChangeInputmopb = (index, event) => {
    const values = [...inputFieldsmopb];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldsmopb(values);
  };
  const handleChangeInputpi = (index, event) => {
    const values = [...inputFieldspi];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldspi(values);
  };
  const handleChangeInputcopi = (index, event) => {
    const values = [...inputFieldscopi];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFieldscopi(values);
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        education_degree1: "",
        education_college1: "",
        education_years1: "",
        education_remarks1: ""
      }
    ]);
  };

  const handleRemoveFields = id => {
    const values = [...inputFields];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFieldsaedu = () => {
    setInputFieldsaedu([
      ...inputFieldsaedu,
      { additional_qualification1: "", year_of_award1: "", awarding_body1: "" }
    ]);
  };

  const handleRemoveFieldsaedu = id => {
    const values = [...inputFieldsaedu];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldsaedu(values);
  };

  const handleAddFieldsexp = () => {
    setInputFieldsexp([
      ...inputFieldsexp,
      {
        experience_postheld1: "",
        experience_from1: "",
        experience_to1: "",
        experience_instiute1: ""
      }
    ]);
  };

  const handleRemoveFieldsexp = id => {
    const values = [...inputFieldsexp];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldsexp(values);
  };

  const handleAddFieldsaoi = () => {
    setInputFieldsaoi([...inputFieldsaoi, { area_of_intrest1: "" }]);
  };

  const handleRemoveFieldsaoi = id => {
    const values = [...inputFieldsaoi];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldsaoi(values);
  };

  const handleAddFieldsmopb = () => {
    setInputFieldsmopb([
      ...inputFieldsmopb,
      { member_of_professional_bodies1: "" }
    ]);
  };

  const handleRemoveFieldsmopb = id => {
    const values = [...inputFieldsmopb];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldsmopb(values);
  };

  const handleAddFieldspi = () => {
    setInputFieldspi([
      ...inputFieldspi,
      {
        research_as_pi_topic1: "",
        research_as_pi_funded1: "",
        research_as_pi_funding_agency1: "",
        research_as_pi_year_to_start1: "",
        research_as_pi_year_of_completion1: ""
      }
    ]);
  };

  const handleRemoveFieldspi = id => {
    const values = [...inputFieldspi];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldspi(values);
  };

  const handleAddFieldscopi = () => {
    setInputFieldscopi([
      ...inputFieldscopi,
      {
        research_as_co_pi_topic1: "",
        research_as_co_pi_funded1: "",
        research_as_co_pi_funding_agency1: "",
        research_as_co_pi_year_to_start1: "",
        research_as_co_pi_year_of_completion1: ""
      }
    ]);
  };

  const handleRemoveFieldscopi = id => {
    const values = [...inputFieldscopi];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFieldscopi(values);
  };

  const uploadFile = (url, file) => {
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        fnSuccess(response);
      })
      .catch(error => {
        fnFail(error);
      });
  };

  const fnSuccess = response => {
    //Add success handling
    console.log(response.data);
    account["profilepic"] = response.data.fileName;
    setAccount(account);
    console.log(account);
  };

  const fnFail = error => {
    console.log(error);
    //Add failed handling
  };

  const save = async e => {
    // console.log(account.fname+account.lname)
    const {
      fname,
      lname,
      designation,
      doj,
      dob,
      profilepic,
      department,
      publications_file
    } = account;
    e.preventDefault();
    // console.log(account);
    console.log(value, "test value");
    const url = "https://api.aiimspatna.edu.in/doctor/update/" + value;

    const payload = {
      fname,
      lname,
      designation,
      doj,
      dob,
      profilepic,
      department,
      //  education_degree1,
      inputFields,
      inputFieldsaedu,
      inputFieldsexp,
      inputFieldsaoi,
      inputFieldsmopb,
      inputFieldspi,
      inputFieldscopi,
      publications_file
    };
    console.log(payload.publications_file + "jjjjjjj");
    const lol = await axios.post(url, payload);
    console.log(payload);
    alert("Your Form Has Been Submited");
  };
  // console.log( a.current);
  useEffect(() => {
    const fetchTokenAndData = async () => {
      try {
        // Fetch token validation
        const tokenResponse = await fetch("https://api.aiimspatna.edu.in/doctor/tokenIsValid", requestOptions);

        if (!tokenResponse.ok) {
          throw new Error("Token validation failed: " + tokenResponse.statusText);
        }

        const tokenData = await tokenResponse.json();
        const id = tokenData && tokenData.user ? tokenData.user.id : null;

        if (!id) {
          throw new Error("User ID not found in token response.");
        }

        console.log(id, "this is token for update");
        setValue(id);

        // Fetch account details
        const accountResponse = await axios.get(`https://api.aiimspatna.edu.in/doctor/${id}`);

        setAccount(accountResponse.data);
        setInputFields(accountResponse.data.inputFields);
        setInputFieldsaedu(accountResponse.data.inputFieldsaedu);
        setInputFieldsexp(accountResponse.data.inputFieldsexp);
        setInputFieldsaoi(accountResponse.data.inputFieldsaoi);
        setInputFieldsmopb(accountResponse.data.inputFieldsmopb);
        setInputFieldspi(accountResponse.data.inputFieldspi);
        setInputFieldscopi(accountResponse.data.inputFieldscopi);

      } catch (error) {
        console.error("Error during fetching:", error);
        alert("An error occurred while fetching data. Please try again later.");
        window.location.replace("https://aiimspatna.edu.in/doctor-login/")
      }
    };

    fetchTokenAndData();
  }, []);




  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>

        {/* <Button type="submit"onclick="location.href='https://aiimspatna.edu.in/doctor-details/"  theme="accent">View Profile</Button><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span>
         */}
      </CardHeader>


      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={save}>
                <Row form>
                  {/* First Name */}
                  <Col md="4" className="form-group">
                    <label htmlFor="feFirstName">First Name</label>
                    <FormInput
                      id="feFirstName"
                      placeholder="First Name"
                      name="fname"
                      value={account.fname}
                      onChange={e => changefirstname(e)}
                    // onChange={() => { }}
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="4" className="form-group">
                    <label htmlFor="feLastName">Last Name</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      name="lname"
                      value={account.lname}
                      onChange={e => changesecondname(e)}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="fePassword">DOB</label>
                    <FormInput
                      type="date"
                      id="fePassword"
                      placeholder="Date Of Birth"
                      name="dob"
                      value={account.dob}
                      onChange={e => changedob(e)}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="4" className="form-group">
                    <label htmlFor="feEmail">Designation</label>
                    <FormInput
                      type="text"
                      id="feEmail"
                      placeholder="Designation"
                      name="designation"
                      value={account.designation}
                      onChange={e => changedesignation(e)}
                      autoComplete="email"
                    />
                  </Col>
                  {/* Password */}
                  <Col md="4" className="form-group">
                    <label htmlFor="fePassword">DOJ</label>
                    <FormInput
                      type="date"
                      id="fePassword"
                      placeholder="Date Of Joining"
                      name="doj"
                      value={account.doj}
                      onChange={e => changedoj(e)}
                      autoComplete="current-password"
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="fePassword">Department</label>
                    <FormInput
                      type="text"
                      id="fePassword"
                      placeholder="Department"
                      name="department"
                      value={account.department}
                      onChange={e => changedepartment(e)}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>

                <Row form>
                  {/* First Name */}

                  {/* Last Name */}
                  <Col md="12" className="form-group">
                    <strong className="text-muted d-block mb-2">
                      Recent Passport Photograph
                    </strong>
                    <div className="custom-file mb-3">
                      <input
                        type="file"
                        value={account[" profile_pic"]}
                        onChange={handleChangedoc}
                        name="profilepic"
                        className="custom-file-input"
                        id="customFile2"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFile2"
                      >
                        {account.profilepic ? account.profilepic : "Choose file..."}
                      </label>
                    </div>
                    {image && image ? <img alt="preview image" src={image} width={100} height={100} /> : null}


                  </Col>
                </Row>

                <CardHeader className="border-bottom">
                  <h6 className="m-0">Education Qualification</h6>
                </CardHeader>
                <br></br>
                {inputFields.map((inputField, index) => (
                  <Row key={index}>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Degree.</label>
                      {/* {console.log(account["inputFields"][index]["education_degree1"]+"hu")}  */}
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="education_degree1"
                        value={inputFields[index]["education_degree1"]}
                        onChange={event => handleChangeInput(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}
                    <Col md="3" className="form-group">
                      <label htmlFor="fePassword">College / University</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="education_college1"
                        value={inputFields[index]["education_college1"]}
                        onChange={event => handleChangeInput(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Passing Years</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="education_years1"
                        value={inputFields[index]["education_years1"]}
                        onChange={event => handleChangeInput(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}
                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Remarks</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="education_remarks1"
                        value={inputFields[index]["education_remarks1"]}
                        onChange={event => handleChangeInput(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <IconButton
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFields}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">
                    Additional Qualification(Fellowships/ Certificate courses
                    etc.)
                  </h6>
                </CardHeader>
                <br></br>
                {inputFieldsaedu.map((inputFieldaedu, index) => (
                  <Row key={index}>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Qualification</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="additional_qualification1"
                        value={
                          inputFieldsaedu[index]["additional_qualification1"]
                        }
                        onChange={event => handleChangeInputaedu(index, event)}
                        autoComplete="email"
                      />
                    </Col>

                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Years of Award</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="year_of_award1"
                        value={inputFieldsaedu[index]["year_of_award1"]}
                        onChange={event => handleChangeInputaedu(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor="fePassword">Awarding Body</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="awarding_body1"
                        value={inputFieldsaedu[index]["awarding_body1"]}
                        onChange={event => handleChangeInputaedu(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <IconButton
                      disabled={inputFieldsaedu.length === 1}
                      onClick={() => handleRemoveFieldsaedu(inputFieldaedu.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldsaedu}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">
                    Experience (after attending post graduate)
                  </h6>
                </CardHeader>
                <br></br>
                {inputFieldsexp.map((inputFieldexp, index) => (
                  <Row form>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Post Held</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="experience_postheld1"
                        value={inputFieldsexp[index]["experience_postheld1"]}
                        onChange={event => handleChangeInputexp(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}
                    <Col md="3" className="form-group">
                      <label htmlFor="fePassword">From </label>
                      <FormInput
                        type="date"
                        id="fePassword"
                        placeholder=""
                        name="experience_from1"
                        value={inputFieldsexp[index]["experience_from1"]}
                        onChange={event => handleChangeInputexp(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">To </label>
                      <FormInput
                        type="date"
                        id="feEmail"
                        placeholder=""
                        name="experience_to1"
                        value={inputFieldsexp[index]["experience_to1"]}
                        onChange={event => handleChangeInputexp(index, event)}
                        autoComplete="email"
                      />
                    </Col>

                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Instiute</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="experience_instiute1"
                        value={inputFieldsexp[index]["experience_instiute1"]}
                        onChange={event => handleChangeInputexp(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>
                    <IconButton
                      disabled={inputFieldsexp.length === 1}
                      onClick={() => handleRemoveFieldsexp(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldsexp}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">Area Of Interest</h6>
                </CardHeader>
                <br></br>
                {inputFieldsaoi.map((inputFieldaoi, index) => (
                  <Row form>
                    {/* First Name */}

                    {/* Last Name */}
                    <Col md="11" className="form-group">
                      {/* <label htmlFor="feLastName">1</label>  */}
                      <FormInput
                        id="feLastName"
                        placeholder="Area Of Interest"
                        name="area_of_intrest1"
                        value={inputFieldsaoi[index]["area_of_intrest1"]}
                        onChange={event => handleChangeInputaoi(index, event)}
                      />
                    </Col>
                    <IconButton
                      disabled={inputFieldsaoi.length === 1}
                      onClick={() => handleRemoveFieldsaoi(inputFieldaoi.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldsaoi}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">
                    Member of Professional Bodies/Committee
                  </h6>
                </CardHeader>
                <br></br>
                {inputFieldsmopb.map((inputFieldmopb, index) => (
                  <Row form>
                    {/* First Name */}

                    {/* Last Name */}
                    <Col md="11" className="form-group">
                      {/* <label htmlFor="feLastName">1</label>  */}
                      <FormInput
                        id="feLastName"
                        placeholder="Member Of Professional Bodies"
                        name="member_of_professional_bodies1"
                        value={
                          inputFieldsmopb[index][
                          "member_of_professional_bodies1"
                          ]
                        }
                        onChange={event => handleChangeInputmopb(index, event)}
                      />
                    </Col>

                    <IconButton
                      disabled={inputFieldsmopb.length === 1}
                      onClick={() => handleRemoveFieldsmopb(inputFieldmopb.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldsmopb}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">Research (as PI)</h6>
                </CardHeader>
                <br></br>
                {inputFieldspi.map((inputFieldpi, index) => (
                  <Row form>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Title of Research</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="research_as_pi_topic1"
                        value={inputFieldspi[index]["research_as_pi_topic1"]}
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}

                    <Col md="2" className="form-group">
                      <label htmlFor="feEmail">Year of Start</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="research_as_pi_year_to_start1"
                        value={
                          inputFieldspi[index]["research_as_pi_year_to_start1"]
                        }
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}
                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Year of Completion</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_pi_year_of_completion1"
                        value={
                          inputFieldspi[index][
                          "research_as_pi_year_of_completion1"
                          ]
                        }
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Funded/Non-Funded</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_pi_funded1"
                        value={inputFieldspi[index]["research_as_pi_funded1"]}
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Funding Agency</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_pi_funding_agency1"
                        value={
                          inputFieldspi[index]["research_as_pi_funding_agency1"]
                        }
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <IconButton
                      disabled={inputFieldspi.length === 1}
                      onClick={() => handleRemoveFieldspi(inputFieldpi.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldspi}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <CardHeader className="border-bottom">
                  <h6 className="m-0">Research (as Co-PI)</h6>
                </CardHeader>
                <br></br>
                {inputFieldscopi.map((inputFieldcopi, index) => (
                  <Row form>
                    <Col md="3" className="form-group">
                      <label htmlFor="feEmail">Title of Research</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="research_as_co_pi_topic1"
                        value={
                          inputFieldscopi[index]["research_as_co_pi_topic1"]
                        }
                        onChange={event => handleChangeInputcopi(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}

                    <Col md="2" className="form-group">
                      <label htmlFor="feEmail">Year of Start</label>
                      <FormInput
                        type="text"
                        id="feEmail"
                        placeholder=""
                        name="research_as_co_pi_year_to_start1"
                        value={
                          inputFieldscopi[index][
                          "research_as_co_pi_year_to_start1"
                          ]
                        }
                        onChange={event => handleChangeInputcopi(index, event)}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}
                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Year of Completion</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_co_pi_year_of_completion1"
                        value={
                          inputFieldscopi[index][
                          "research_as_co_pi_year_of_completion1"
                          ]
                        }
                        onChange={event => handleChangeInputcopi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Funded/Non-Funded</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_co_pi_funded1"
                        value={
                          inputFieldscopi[index]["research_as_co_pi_funded1"]
                        }
                        onChange={event => handleChangeInputpi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>

                    <Col md="2" className="form-group">
                      <label htmlFor="fePassword">Funding Agency</label>
                      <FormInput
                        type="text"
                        id="fePassword"
                        placeholder=""
                        name="research_as_co_pi_funding_agency1"
                        value={
                          inputFieldscopi[index][
                          "research_as_co_pi_funding_agency1"
                          ]
                        }
                        onChange={event => handleChangeInputcopi(index, event)}
                        autoComplete="current-password"
                      />
                    </Col>
                    <IconButton
                      disabled={inputFieldscopi.length === 1}
                      onClick={() => handleRemoveFieldscopi(inputFieldcopi.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFieldscopi}>
                      <AddIcon />
                    </IconButton>
                  </Row>
                ))}

                <Row form>
                  {/* First Name */}

                  {/* Last Name */}
                  <Col md="12" className="form-group">
                    <strong className="text-muted d-block mb-2">
                      Publication (copy and paste in Vancouver Style)
                    </strong>
                    <FormTextarea
                      rows="10"
                      type="text"
                      id="feEmail"
                      placeholder=""
                      name="publications_file"
                      value={account.publications_file}
                      onChange={e => changepub(e)}
                      autoComplete="publications_file"
                    />
                    {/* <div className="custom-file mb-3">
                      <input type="text" name="publications_file"  value={account['publications_file']}onChange={handleChange} className="custom-file-input" id="customFile1" />
                     
                    </div> */}
                  </Col>
                </Row>

                <Button type="submit" theme="accent">
                  Update Account
                </Button>
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                {/* <Button theme="accent">Update CV In Same Format</Button> */}
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
